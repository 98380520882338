import React from "react";
import { graphql, Link } from "gatsby";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Helmet } from "react-helmet";

import { BlogPostQuery } from "../../graphql-types";
import { Footer, Header, ShareButtons } from "../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1200,
      margin: "75px auto 75px auto",
    },
    content: {
      maxWidth: 800,
      margin: "auto",

      "& *": {
        lineHeight: "2rem",
      },

      "& > *": {
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },

      "& > p": {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),

        "& span": {
          margin: "0 4px",
        },
      },

      "& h1": {
        fontSize: "2rem",
        lineHeight: "3rem",
        marginBottom: theme.spacing(3),
      },

      "& p": {
        fontSize: "1rem",
      },
    },
    share_section: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: theme.spacing(8),
    },
    author_section: {
      borderTop: "1px solid #F4F4F4",
      borderBottom: "1px solid #F4F4F4",
      padding: "64px 0 64px 0",
      marginTop: theme.spacing(8),

      "& > div": {
        display: "flex",
        maxWidth: 800,
        margin: "auto",

        "& *": {
          marginBlockStart: 0,
          marginBlockEnd: 0,
        },

        "& img": {
          width: 130,
          height: 130,
          borderRadius: "50%",
          marginRight: theme.spacing(4),
        },
      },
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(8),
    },
    button: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 100,
      padding: theme.spacing(2),
      boxShadow: "0px 3px 6px #00000029",
    },
  })
);

interface Props {
  data: BlogPostQuery;
  pageContext: {
    nextSlug: string | null;
    prevSlug: string | null;
    slug: string;
  };
}

const BlogPostTemplate: React.FC<Props> = ({ data, pageContext }: Props) => {
  const { wpPost, site } = data;

  const classes = useStyles();

  return (
    <div>
      <Helmet title={wpPost.title}>
        <meta name="description" content={wpPost.title} />
        <meta name="image" content={wpPost.featuredImage.node.localFile.url} />

        {/* Open Graph */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={wpPost.title} />
        <meta property="og:description" content={wpPost.title} />
        <meta
          property="og:url"
          content={`${site.siteMetadata.url}/blog/${wpPost.slug}`}
        />
        <meta
          property="og:image"
          content={wpPost.featuredImage.node.localFile.url}
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={wpPost.title} />
        <meta name="twitter:description" content={wpPost.title} />
        <meta
          name="twitter:url"
          content={`${site.siteMetadata.url}/blog/${wpPost.slug}`}
        />
        <meta
          name="twitter:image"
          content={wpPost.featuredImage.node.localFile.url}
        />
      </Helmet>

      <Header />

      <div className={classes.container}>
        <img
          style={{ width: "100%", height: 610, borderRadius: 10 }}
          src={wpPost.featuredImage.node.localFile.childImageSharp.original.src}
        />

        <div className={classes.content}>
          <p>
            {wpPost.author.node.name} <span>&#8226;</span> {wpPost.date}
          </p>

          <h1>{wpPost.title}</h1>

          <div
            dangerouslySetInnerHTML={{
              __html: wpPost.content,
            }}
          ></div>

          <div className={classes.share_section}>
            <ShareButtons
              url={`${site.siteMetadata.url}/blog/${wpPost.slug}`}
            />
          </div>
        </div>

        <div className={classes.author_section}>
          <div>
            <img
              src={wpPost.author.node.avatar.url}
              alt={wpPost.author.node.name}
            />
            <div style={{ marginTop: 8 }}>
              <Typography>Written by</Typography>
              <Typography
                variant="body1"
                color="primary"
                style={{ fontSize: "1.5rem" }}
              >
                {wpPost.author.node.name}
              </Typography>
              <Typography>{wpPost.author.node.description}</Typography>
            </div>
          </div>
        </div>

        <div className={classes.actions}>
          <Button
            style={{ visibility: !pageContext.nextSlug ? "hidden" : "visible" }}
            className={classes.button}
            component={Link}
            to={`/blog/${pageContext.nextSlug}`}
          >
            NEXT ARTICLE
          </Button>

          <Button
            style={{ visibility: !pageContext.prevSlug ? "hidden" : "visible" }}
            className={classes.button}
            component={Link}
            to={`/blog/${pageContext.prevSlug}`}
          >
            PREVIOUS ARTICLE
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export const query = graphql`
  query BlogPost($slug: String!) {
    site {
      siteMetadata {
        url
      }
    }
    wpPost(slug: { eq: $slug }) {
      id
      title
      uri
      excerpt
      content
      date(formatString: "d MMMM Y")
      slug
      featuredImage {
        node {
          localFile {
            url
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
      author {
        node {
          name
          description
          avatar {
            url
          }
        }
      }
    }
  }
`;

export default BlogPostTemplate;
